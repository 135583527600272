.design {
  max-width: 542px;
  width: 100%;
  margin: 5em auto;
}

a {
  color: #fff;
}

a:hover {
  color: #fff;
}

.btn-primary {
  background: #006c76;
  border-color: #006c76;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: #098894;
  border-color: #098894;
}

.btn-secondary {
  background: #860fa7;
  border-color: #860fa7;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
  background: #a125c4;
  border-color: #a125c4;
}

.bg-dark {
  background: #000000 !important;
}

.bg-secondary {
  background: #0cc5b3 !important;
}

.bg-primary {
  background: #860fa7 !important;
}

.bg-success-0 {
  --bs-bg-opacity: 0;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

#rank {
  max-width: 640px;
  margin: 25px auto;
}

#rank .col:nth-child(2n+1) {
  max-width: 20%;
}

#rank .icon {
  position: relative;
  max-width: 100px;
  margin: 2em auto;
}
#rank .icon img {
  width: 100%;
}
#rank .icon p {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
  height: 25px;
  width: 25px;
  text-align: center;
  z-index: 1311;
  padding: 0;
  margin: auto;
  display: block;
}

#rank .barrexp {
  position: relative;
}
#rank .barrexp p {
  position: relative;
  display: inline-block;
  font-size: 0.8em;
}
#rank .barrexp svg {
  border: 1px solid rgb(77, 77, 77);
  position: relative;
  display: inline-block;
  max-width: 300px;
  margin-right: 10px;
  width: 100%;
}

#rank p {
  margin: auto;
  position: relative;
}

#rank .xp {
  position: absolute;
  fill: #D5E8D4;
  left: 0;
  top: 0;
  z-index: 1;
  transition: 1s ease-out;
}

#rank p#eventValue {
  width: 100%;
  margin-bottom: 0;
  text-align: left;
  height: 1em;
}

#rank p#eventValue span {
  position: absolute;
}

.legend {
  max-width: 750px;
  margin: auto;
}

.anim {
  animation-duration: 1s;
  animation-name: slideOpacity;
}

@keyframes slideOpacity {
  0% {
    left: 30%;
    color: rgba(0, 0, 0, 0);
  }
  100% {
    left: 0%;
    color: rgb(0, 0, 0);
  }
}

