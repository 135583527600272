// Custom.scss
 
$color1: #006c76;
$color1-hover: #098894;

$color2: #000000; 
$color2-hover: #3f3f3f; 
$color3: #0cc5b3;
$color3-hover: #08ebd4;
$color4: #860fa7;  
$color4-hover: #a125c4;

$color5: #fff;
  
.design{ max-width: 542px;width: 100%; margin: 5em auto;}

a{color: $color5} 
a:hover{color: $color5; }

.btn-primary {
    background:$color1; 
    border-color: $color1;} 

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {  background: $color1-hover; border-color: $color1-hover;}


.btn-secondary {
    background:$color4; 
    border-color: $color4;} 

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {  background: $color4-hover; border-color: $color4-hover;}

.bg-dark{background:$color2!important} 
.bg-secondary{ background: $color3!important}
.bg-primary{ background: $color4!important}
 
.bg-success-0 {
    --bs-bg-opacity: 0;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
  }
     
  #rank {max-width:  640px;margin:25px auto; }
  
  #rank .col:nth-child(2n+1) {
    
    max-width: 20%;
}
  #rank .icon {
    
    position:relative;
    max-width: 100px;
    margin: 2em auto;  

    img {width: 100%;}
    p {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: #000;
      height: 25px;
      width: 25px;
      text-align: center;
      z-index: 1311;
      padding: 0;
      margin: auto;
      display: block;
    }
  }

  #rank .barrexp{

    position:relative;
    
    p{ position: relative;display: inline-block;  font-size: 0.8em;  }

    svg {border:1px solid rgb(77, 77, 77);
      position:relative;
      display:inline-block;
       max-width:300px;
        margin-right:10px;
          width:100%}
  }  
  
  #rank p {margin:auto; position:relative}
  
  #rank .xp {position: absolute; fill: #D5E8D4;left:0;  top:0; z-index: 1; 
    transition: 1s ease-out;  }

  #rank p#eventValue{width:100%; margin-bottom:0; text-align: left;height: 1em;} 
  #rank p#eventValue span{position: absolute;}

.legend{max-width: 750px; margin:auto; }

  .anim{ 
  animation-duration: 1s;
  animation-name: slideOpacity; 
  
  }
  @keyframes slideOpacity {
   0% {
      left:30%;
      color: rgba(0, 0, 0, 0)
     
    }
  
    100%{
      left: 0%;
       color: rgba(0, 0, 0, 1)
    }
  } 


  @media screen and (max-width: 540px) {
    body {
      
    }
  }